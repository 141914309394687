import React, { useState } from "react";
import { changeHandler, submitHandler, FORM_SUBMISSION_STATUS } from './Contact.utils';

const ContactForm = () => {

    const [formData, setFormData] = useState({});
    const [formResponse, setFormResponse] = useState({});

    return (
        <div className="col-12 mt-5">
            <div className="row">
                <div className="col-md-6 offset-md-3 contact-box">
                    <div class="row">
                        <div className="col-12">
                            <h4><strong>Your Message</strong></h4>
                        </div>
                        <form className="col-12 mt-3" onSubmit={e => submitHandler(e, formData, setFormData, setFormResponse)}>
                            <div className="row">
                                <div class="col-md-6  mt-4">
                                    <div class="form-floating">
                                        <input value={formData.fname} onChange={e => changeHandler(e, formData, setFormData)} type="text" class="form-control" id="floatingFirstname" placeholder="Firstname" name='fname' />
                                        <label for="floatingFirstname">Firstname</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-floating">
                                        <input value={formData.lname} onChange={e => changeHandler(e, formData, setFormData)} type="text" class="form-control" id="floatingLastname" placeholder="Lastname" name='lname' />
                                        <label for="floatingLastname">Lastname</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-floating">
                                        <input value={formData.phone} onChange={e => changeHandler(e, formData, setFormData)} type="text" class="form-control" id="floatingphonne" placeholder="Phone Number" name='phone' />
                                        <label for="floatingphonne">Phone Number</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-floating">
                                        <input value={formData.email} onChange={e => changeHandler(e, formData, setFormData)} type="email" class="form-control" id="floatingEmail" placeholder="Email" name='email' />
                                        <label for="floatingEmail">Email</label>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="form-floating">
                                        <textarea onChange={e => changeHandler(e, formData, setFormData)} class="form-control" placeholder="Write your message" id="floatingMsg" name='message'>{formData.message}</textarea>
                                        <label for="floatingMsg">Write your message</label>
                                    </div>
                                </div>
                                <div class="col-12 mt-4 text-center">
                                    <button type="submit" className="contact-button">Submit</button>
                                </div>
                            </div>
                        </form>
                        {formResponse.status === FORM_SUBMISSION_STATUS.failed &&
                            <div className="col-12 mt-3">
                                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Request failed!</strong> {formResponse.message}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>}
                        {formResponse.status === FORM_SUBMISSION_STATUS.passed &&
                            <div className="col-12 mt-3">
                                <div class="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Great!</strong> {formResponse.message}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;