
import Herobanner from '../components/Herobanner/Herobanner';
import Content from '../components/Content/Content';
import Slider from '../components/Slider/Slider';
import Contact from '../components/Contact/Contact';
import ServiceSlider from '../components/ServiceSlider/ServiceSlider';
import Testimonial from '../components/Testimonial/Testimonial';
import SEO from './SEO';


function Home() {
    return (
        <>
            <SEO title= 'Home | The Vacation Pod'/>
            <Herobanner />
            <Content />
            <Slider />
            <Contact />
            <ServiceSlider />
            <Testimonial />
            
        </>
    );
}

export default Home;