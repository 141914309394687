import React from "react";
import GoDown from '../../assets/logo/icon-logo.png';
import './Herobanner.css';

const Herobanner = () => {
    return (
        <section className="container-fluid hero-banner p-t-md-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="display-1 manjari-bold">The Vacation Pod, <br /> Your Next Adventure</h1>
                    </div>
                    <div className="col-12">
                        <h5>Villa/GuestHouses/HomeStays</h5>
                    </div>
                    <div className="col-12 mt-5">
                        <a href="#contact-form"><img className="go-down bounce" src={GoDown} alt="" /></a>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Herobanner;