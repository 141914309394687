import S1 from '../../assets/slider/1.jpg';
import S2 from '../../assets/slider/2.jpg';
import S3 from '../../assets/slider/3.jpg';
import S4 from '../../assets/slider/4.jpg';
import S5 from '../../assets/slider/5.jpg';
import S6 from '../../assets/slider/6.jpg';
import S7 from '../../assets/slider/7.jpg';
import S8 from '../../assets/slider/8.jpg';
import S9 from '../../assets/slider/9.jpg';
import S10 from '../../assets/slider/10.jpg';
import S11 from '../../assets/slider/11.jpg';
import S12 from '../../assets/slider/12.jpg';
import S13 from '../../assets/slider/13.jpg';
import S14 from '../../assets/slider/14.jpg';
import S15 from '../../assets/slider/15.jpg';
import S16 from '../../assets/slider/16.jpg';
import S17 from '../../assets/slider/17.jpg';
import S18 from '../../assets/slider/18.jpg';
import S19 from '../../assets/slider/19.jpg';

export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const slider = [S1, S2, S3, S4, S5, S6, S7 , S8, S9, S10, S11, S12, S13, S14, S15, S16, S17, S18, S19];