
import SEO from './SEO';

function TermsConditions() {
    return (
        <>
            <SEO title='Terms and Conditions | The Vacation Pod' />
            <section className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-section">
                            <h1 className="mt-page-header">Terms and Conditions</h1>
                            <ul className="terms-lavel-1">
                                <li>
                                    <h2>APPLICABILITY</h2>
                                    <p>The usage of this website (“Website”) operated by TheVacationPod Private Limited, a company incorporated in India under the Companies Act,2013(“Company”) or any services provided by the Companythrough this Website, shall be governed by these terms & conditions (“Terms & Conditions”or “Terms”).</p>
                                    <p>In these Terms & Conditions, the term “User” refers to you, the Person or entity using theWebsite for the service rendered by the Company and shall include any contractors and otherpersons using the Website. It is clarified that in case of a company, partnership, trust or anyother legal entity which uses or accesses the services, the Website, User shall include suchcompany, partnership, trust or any other legal entity. Company offers the Website, and anyservices conditioned upon the User’s acceptance of these Terms & Conditions.</p>
                                    <p>Please read these Terms & Conditions carefully before accessing or using the Website, andany services provided by Company. By accessing or using any part of the Website, by the useof any services provided by Company, the User agree to be bound by these Terms &Conditions. If the User does not agree to all the Terms & Conditions, then the User may notaccess the Website or services provided by Company.</p>
                                    <p>The User can review the most current version of the Terms & Conditions at any time on thispage. Company reserves the right to update, change or replace any part of these Terms &Conditions by posting updates and/or changes to the Website. It is the User’s responsibility tocheck this page periodically for changes. Following the posting of any changes, the User’scontinued use of or access to the Website or the use of the product or services from Company,constitutes acceptance of such revised Terms & Conditions.</p>
                                    <p>THESE TERMS AND CONDITION ARE AN ELECTRONIC RECORD IN THE FORMOF AN ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGYACT, 2000, AND RULES MADE THEREUNDER AND THE AMENDED PROVISIONSPERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTESAS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMSOF USE DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITALSIGNATURE.</p>
                                </li>
                                <li>
                                    <h2>CHANGES TO THE TERMS & CONDITIONS</h2>
                                    <p>2.1. The Company may revise these Terms & Conditions at any time by amending this page.The User must check this page from time to time to take notice of any changes made to these Terms, as they are binding on the User.</p>
                                    <p>2.2. The Company may, without prior notice, change all or any features of the Website; add orre move functionalities or features; stop providing the Website; or create usage limits for the Website.</p>
                                </li>
                                <li>
                                    <h2>ACCEPTANCE OF THE TERMS & CONDITIONS</h2>
                                    <p>3.1. The User agrees that the User shall be bound by these Terms by accessing the Website,using the Website or by otherwise availing of the services from the Company.</p>
                                </li>
                                <li>
                                    <h2>ELIGIBILITY</h2>
                                    <p>4.1. By agreeing to these Terms & Conditions, a User (when such User is a natural person)represents that the User is at least the age of majority i.e. 18 years, and is competent tocontract. Additionally, where the User is registering on behalf of, and is the authorizedrepresentative of, a company, partnership or any other legal entity, such User has beenduly authorized by such entity and is permitted under applicable laws to accept theseTerms & Conditions. The User represents and warrants that the User has the full powerand authority to accept the Terms & Conditions, access the Website and use the servicesprovided by the Company, under these Terms & Conditions and that these Terms &Conditions are binding upon the User and enforceable in accordance with their terms.Where the Services are availed by a company, partnership or any other legal entity, theUser represents and warrants that the entity has the full power and authority to accept theTerms & Conditions and such Terms & Conditions shall bind such company, partnershipor any other legal entity, as applicable.</p>
                                    <p>4.2. In case the Company alters or amends the eligibility criteria to be registered for accessingthe Website or receiving services of the Company and the User no longer complies withthe new eligibility criteria, as determined by the Company in its sole discretion, the Useraccepts that the Company may restrict or suspend the access to the Website or receivingservices of the Company without any liability for the Company. The User is solelyresponsible for ensuring that the User are in compliance with these Terms & Conditions.</p>
                                    <p>4.3. The User shall ensure that there are proper encryption and security measures at itsrespective desktop, mobile device or other device used to access the Website to preventany hacking and the User accepts all liabilities with respect to any compromise orunauthorized use or disclosure or access to such information.</p>
                                    <p>4.4. The User may not use the services provided by the Company or the Website for any illegalor unauthorised purpose nor may the User, in the use of the Website, violate any applicablelaws (including but not limited to copyright laws). The Company reserves the right torefuse or discontinue any services provided by the Company or the Website to anyone forany reason at any time.</p>
                                </li>
                                <li>
                                    <h2>RESTRICTIONS ON THE USE OF THE WEBSITE</h2>
                                    <p>5.1. The User shall not redistribute, sublicense, rent, publish, sell, assign, lease, market,transfer, or otherwise make the Website or any component or content thereof, available toany third parties prior the prior written consent of the Company.</p>
                                    <p>5.2. The User shall not circumvent or disable any digital rights management, usage rules, orother security features of the Website. remove, alter, or obscure any proprietary notices(including copyright notices) on any portion of the Website, and not use the the Website ina manner that threatens the integrity, performance, or availability of the Website.</p>
                                    <p>5.3. Without limiting the foregoing, the User agrees that the User shall not use the Website orthe services of the Company to undertake any of the following actions or to display,upload, modify, publish, distribute, disseminate, transmit, update or share any informationthat:</p>
                                    <p>(a) that the User does not have a right to make available under any law or contractualor fiduciary relationship, unless the User owns or controls the rights thereto orhave received all necessary consents for such use of the content;</p>
                                    <p>(b) is defamatory, vulgar, obscene, pornographic, paedophilic, invasive of anotherperson’s privacy, including bodily privacy, insulting or harassing on the basis ofgender, libellous, racially or ethnically objectionable, relating or encouragingmoney laundering or gambling, or otherwise inconsistent with or contrary to thelaws in force;</p>
                                    <p>(c) is harmful to minors and children;</p>
                                    <p>(d) infringes patent, trademark, copyright or other proprietary rights or intellectualproperty rights of any person or entity;</p>
                                    <p>(e) violates any applicable law, regulation or generally accepted practices orguidelines in India;</p>
                                    <p>(f) deceives or misleads any other user of the Website, the Company or any otherperson the origin of any messages on the Website or knowingly and intentionallycommunicates any information which is patently false or misleading in nature butmay reasonably be perceived as a fact;</p>
                                    <p>(g) impersonates any person or entity, or falsely state or otherwise misrepresent theUser’s affiliation with a person or entity;</p>
                                    <p>(h) threatens the unity, integrity, defence, security or sovereignty of India, friendlyrelations with foreign states, or public order, or causes incitement to thecommission of any cognisable offence or prevents investigation of any offence oris insulting other nation;</p>
                                    <p>(i) contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or othercomputer programming routines, code, files or such other programs that mayharm the Website, interests or rights of other users or limit the functionality ofany computer software, hardware or telecommunications, or that may harvest orcollect any data or personal information about other Users without their consent;and</p>
                                    <p>(j) is patently false and untrue, and is written or published in any form, with theintent to mislead or harass a person, entity or agency for financial gain or to causeany injury to any person.</p>
                                    <p>5.4. Without limiting the foregoing, the User agrees that the User shall not:</p>
                                    <p>(a) decompile, reverse engineer, or disassemble the contents of the Website ormodify, copy, distribute, transmit, display, perform, reproduce, publish, license,create derivative works from, transfer, or sell any information or softwareobtained from the Website, or remove any copyright, trademark registration, orother proprietary notices from the contents of the Website;</p>
                                    <p>(b) (i) use the Website and any of the Company’s services for commercial purposesof any kind except as permitted, or (ii) advertise or sell the Website or anyservices of the Company or domain names or otherwise (whether or not forprofit), or solicit others (including, without limitation, solicitations forcontributions or donations) or use any public forum for commercial purposes ofany kind, or (c) use the Website or the services of the Company in any way that isunlawful, or harms the Company or any other person or entity as determined bythe Company;</p>
                                    <p>(c) Engage in any activity which would interfere with any other person’s use orenjoyment of the Website or the services of the Company including engage indisruptive attacks such as denial of service attack on the Website or the servicesof the Company;</p>
                                    <p>(d) Access or use the Website or the services of the Company in any manner that could damage, disable, overburden or impair any of the Website’s servers or thenetworks;</p>
                                    <p>(e) Disrupt or interfere with the security of, or otherwise cause harm to the Website or the services of the Company, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected;</p>
                                    <p>(f) Use deep-links, page-scrape, robot, spider or other automatic device, program,algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Website or the services of the Company, to access, acquire,copy or monitor any portion of the Website or the services of the Company, or in any way reproduce or circumvent the navigational structure or presentation of the Website, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through theWebsite or the services of the Company; and</p>
                                    <p>(g) use the Website or the services of the Company for purposes that are not permitted by: (i) these Terms & Conditions; and (ii) any applicable law,regulation or generally accepted practices or guidelines in the relevant jurisdiction.</p>
                                    <p>5.5. The User shall not use the Website or the services of the Company for any purpose that might be construed as contrary or repugnant to any applicable law, regulation, guideline,judicial dicta, or public policy or for any purpose that might negatively prejudice the good will of the Company, its partners or violate the Terms & Conditions.</p>
                                    <p>5.6. The Company reserves the right to suspend/discontinue the Website or the services of theCompany and any other products/services to the User and/or to sever its relationship with the User, at any time, for any cause, including, but not limited, to the following:</p>
                                    <p>(a) For any suspected violation of the rules, regulations, orders, directions,notifications, laws, statutes, from time to time or for any violation of the terms and conditions mentioned in these Terms & Conditions.</p>
                                    <p>(b) For any suspected discrepancy in the particular(s), documentation or information provided by the User;</p>
                                    <p>(c) To combat potential fraud, sabotage, wilful destruction, threat to national security,for any other force majeure reasons etc.;</p>
                                    <p>(d) If the same is due to technical failure, modification, upgradation, variation,relocation, repair, and/or maintenance due to any emergency or for any technical reasons;</p>
                                    <p>(e) If the same is due to any transmission deficiencies caused by topographical and geographical constraints/limitations;</p>
                                    <p>(f) If the User does not have the control over the User’s login details and password or the User’s account is compromised in any other manner;</p>
                                    <p>(g) If the User indulges in any abusive or aggressive or threatening behaviour or anyact or any intimidation or harassment of any kind (be it verbal or physical orwritten) against any employee / representative / agent of the Company or anyother User; and</p>
                                    <p>(h) If the Company believes, in its reasonable opinion, that cessation/ suspension isnecessary.</p>
                                </li>
                                <li>
                                    <h2>ACCURACY OF INFORMATION</h2>
                                    <p>6.1. The Company is not responsible if information made available on the Website (includingany information in relation to the services of the Company) is not accurate, complete orcurrent. The material on the Website is provided for general information only and shouldnot be relied upon or used as the sole basis for making decisions without consulting,primary, more accurate, more complete or more timely sources of information.</p>
                                    <p>6.2. The Website may contain certain historical information. Historical information,necessarily, is not current and is provided for the User’s reference only. The Companyreserves the right to modify the contents of the Website at any time, but the Company hasno obligation to update any information on the Website. The User agrees that it is theUser’s responsibility to monitor changes to the Website or any other information providedby the Company.</p>
                                </li>
                                <li>
                                    <h2>WARRANTIES</h2>
                                    <p>7.1. No warranty of any kind, implied, express or statutory, including but not limited to thewarranties of non-infringement of third party rights, title, merchantability, satisfactoryquality or fitness for a particular purpose, is given in conjunction with the Website or theservices of the Company. While the Company may apply such technology as it deems fitto ensure the security of and prevent unauthorised access to its services, the Companydoes not warrant that products/services or any content/data will be provideduninterrupted or free from errors or that any identified defect will be immediatelycorrected. Further, no warranty is given that products/services or any data/content arefree from any computer virus or other malicious, destructive or corrupting code, agent,program or macros. The Company provides the Website or the services of the Companyand other products/services on an “as is”, “where is”, “with all faults” basis.</p>
                                    <p>7.2. ALL OTHER WARRANTIES, WHETHER LEGAL, EXPRESS OR IMPLIED,INCLUDING BUT NOT LIMITED TO ANY EXPRESS OR IMPLIED</p>
                                    <p>WARRANTIES OF MERCHANTABILITY, OF FITNESS FOR A PARTICULARUSAGE OR FOR THE INTENDED USE THEREOF ARE EXPRESSLYEXCLUDED IN CONNECTION WITH THE USE OF THE WEBSITE OR THESERVICES OF THE COMPANY.</p>
                                    <p>7.3. The Company shall make all best efforts to provide uninterrupted services subject todown time and regular maintenance Notwithstanding anything in these Terms &Conditions, the User acknowledges that the Website or the services of the Company maynot be uninterrupted or error free or free from any virus or other malicious, destructive orcorrupting code, program or macro. The Company shall adopt all such best technical andnon-technical security measures that it considers are appropriate and are the same as thatis prevalent in the industry, however the Company does not guarantee that such securitymeasures cannot be subverted to gain unauthorized access. In the event of interruption tothe Website or the services of the Company or loss of use and/or access, the Companyshall be to use best endeavors to restore access as soon as reasonably possible.</p>
                                </li>
                                <li>
                                    <h2>TRAVEL ADVISORY AND VISA REQUIREMENTS</h2>
                                    <p>8.1. It is the responsibility of the User to ensure that the satisfaction all applicable legalrequirements when entering a country or territory, including its visa and healthrequirements. As these requirements are subject to change without notice, the Companyrecommends that the User review travel prohibitions, warnings, announcements andadvisories and verify with the appropriate consulate prior to booking and again beforedeparture. It is the responsibility of User to ensure that you are either insured by the User’sown insurance company, or by the third-party who may provide insurance coverage. TheCompany takes no responsibility in the insurance process and recommends that you aresufficiently covered with a comprehensive travel insurance policy prior to departure.</p>
                                    <p>8.2. By displaying the services in or to particular destinations, the User does not represent orwarrant that travel to such points is advisable or risk-free and User shall not be held liablefor damages or losses that may result from travel to such destinations. The User is stronglyadvised to check the relevant travel advisories issued by the relevant government for anycountry and to take the necessary precautions.</p>
                                </li>
                                <li>
                                    <h2>WEBSITE CONTENT AND AVAILABILITY</h2>
                                    <p>9.1. The User make no guarantees as to the authenticity, accuracy or completeness of anycontent on the Website, including content provided by other Users or our vendors. Thedisplayed prices for our services are dynamic and can change during or after the search bythe User. Once the User has a confirmed booking, subsequent changes in displayed priceswill not affect the price of the booking of the User. The Company makes no guaranteethat the content available on the Website has not been altered through technical defects orby unauthorised third parties.</p>
                                    <p>9.2. All content accessed by the User using the Website is at the User’s own risk and youwill be solely responsible for any damage or loss to any party resulting therefrom.Under no circumstance shall the Company be liable in any way for any loss ordamage of any kind incurred in connection with the use of or exposure to any contentaccessed on the Website, including but not limited to any errors or omissions, or anyadditions, removals, or modifications that the Company made on the Website. TheCompany makes no guarantee that the Website will always be available or uninterrupted.We will not be liable to the User for any loss or damage resulting from any unavailabilityor interruption of our Website or services.</p>
                                    <p>9.3. The Website does not constitute and should not be regarded as a recommendation orendorsement of any services or third-party service provider listed on the Website. TheCompany hereby expressly disclaim any representation, warranty or undertaking inrelation to the quality, status, features, or adequacy of any services listed on the Website.</p>
                                </li>
                                <li>
                                    <h2>Cancellation and Refund Policy:</h2>
                                    <p>The following cancellation policy will be applicable as per our Company regulations:</p>
                                    <p>If the guest wishes to cancel prior to 30 days of the check-in date, 100% of the booking amount will be refunded.</p>
                                    <p>For any cancellations made within 30 days of the check-in date, the booking amount will be non-refundable.</p>
                                    <p>Refunds are applicable only in cases where 100% payment has been made; partial payments are non-refundable.</p>
                                    <p>Refunds will be processed in 7-9 days post cancellation request</p>
                                </li>
                                <li>
                                    <h2>THIRD PARTY LINKS</h2>
                                    <p>11.1. Hyperlinks on this Website to websites operated by third parties are only provided for yourreference. The Company takes no responsibility for the content of these websites and has nocontrol over their content, access and privacy policies. The Company also takes noresponsibility for the consequences of accessing these websites, including exposure to hard-and software viruses, spy and malware. The Company do not endorse or recommend thesewebsites or any of their contents.</p>
                                    <p>11.2. Any third party that wishes to establish links to our Website should notify the Company oftheir intention prior to doing so. The Company may deny permission for any such links tothe Website. If the Company gives its permission for any such links, it is not under anyobligation to establish reciprocal links with the third party.</p>
                                </li>
                                <li>
                                    <h2>INTELLECTUAL PROPERTY</h2>
                                    <p>12.1. All Intellectual Property Rights the Website or the services of the Company belong to theCompany or the applicable third-party owner of such rights. Nothing in these Terms &Conditions shall operate to transfer any Intellectual Property Rights beyond the specificlicenses, as may be specifically agreed in writing. The Company retains ownership of allright, title to, and interest in any and all Intellectual Property Rights developed, owned,licensed or created by the Company.</p>
                                    <p>12.2. No part or parts of the Website may be reproduced, distributed, republished, displayed,broadcast, hyperlinked, transmitted, adapted, modified to create derivative works orotherwise commercially exploited in any manner or by any means or stored in an information retrieval system without the Company’s prior written permission. The User mayview, print or use the Company’s content for personal, non-commercial use only, providedfurther that the User does not modify the content and that the User retains all copyrightnotices and other proprietary notices contained in the content.</p>
                                    <p>12.3. The User shall not claim any rights or interest in the Intellectual Property Rights of theCompany or its partners, or in connection with any other services, features or productoffered by the Company to the User. In no event shall the User alter, tamper, vary, modify,change, disassemble, decompile, or reverse engineer any Intellectual Property Rights of theCompany (including without limitation any Intellectual Property Rights licensed to theCompany by any other Person) (collectively, “IP Property”), or permit others to do so.Altering, tampering, varying, modifying, changing, disassembling, decompiling and reverseengineering include, without limitation:</p>
                                    <p>(a) converting the IP Property from a machine-readable form into a human-readableform;</p>
                                    <p>(b) disassembling or decompiling the IP Property by using any means or methods totranslate machine-dependent or machine-independent object code into the originalhuman-readable source code or any approximation thereof;</p>
                                    <p>(c) examining the machine-readable object code that controls the IP Property’soperation and creating the original source code or any approximation thereof by,for example, studying the IP Property’s behaviour in response to a variety ofinputs;</p>
                                    <p>(d) performing any other activity related to the IP Property that could be construed tobe reverse engineering, disassembling, or decompiling; or</p>
                                    <p>(e) making any alteration or change to the IP Property without the express priorwritten consent of the Company.</p>
                                </li>
                                <li>
                                    <h2>INDEMNIFICATION</h2>
                                    <p>The User (“Indemnifying Party”) shall indemnify the Company, including all employees,officers and directors (“Indemnified Party”) against, and hold harmless from any and allactual and direct losses, including but not limited to third party claims, suits, actions orproceedings (“Losses”) against the Indemnified Party, where such Losses arise out of orrelate to the Indemnifying Party’s use of the services or the Indemnifying Party’s breach ofany requirement, representation or warranty, covenant or confidentiality obligationscontained in this Terms and Conditions or Privacy Policy, provided that: (a) the IndemnifiedParty promptly notifies the Indemnifying Party in writing of such Losses; (b) theIndemnifying Party has sole control over the defense or settlement of the Losses; and (c) theIndemnified Party cooperates fully in the defense or settlement of the Losses. Thisindemnification shall include the Indemnified Party’s reasonable attorney’s fees incurred inthe defense of the action giving rise to any damages, which are indemnifiable under thissection.</p>
                                </li>
                                <li>
                                    <h2>LIMITATION OF LIABILITY</h2>
                                    <p>Notwithstanding anything to the contrary elsewhere contained in this Agreement, theCompany in any event, regardless of the form of Losses, shall not be liable for any indirect,special, punitive, exemplary, speculative or consequential damages, including, but not limitedto, any loss of use, loss of data, business interruption, and loss of income or profits,irrespective of whether it had a notice of the possibility of any such damages. The totalliability of the Company shall not exceed INR [●].</p>
                                </li>
                                <li>
                                    <h2>WAIVER</h2>
                                    <p>If the Company does not exercise a right under these Terms & Conditions, shall not constitutea waiver of such right. Waiver of any right, remedy or breach of any subject matter containedin these Terms & Conditions shall not be viewed as a waiver by the Company, unlessspecifically agreed by the Company in writing.</p>
                                </li>
                                <li>
                                    <h2>FORCE MAJEURE</h2>
                                    <p>For purposes of this Terms, “Force Majeure Event” means any event or circumstance,regardless of whether it was foreseeable (including without limitation an act of war, hostility,or sabotage; act of God; electrical, internet or telecommunication outage that is not caused bythe obligated party; government restrictions (including the denial or cancellation of anyexport or other license); criminal, revolutionary, or terrorist activity), that: (a) was not causedby a party and, (b) prevents that party from complying with any of its obligations pursuant tothese Terms and Conditions (other than an obligation to pay money) or provision of theWebsite or the Website. If a Force Majeure Event occurs, the Company will be excused fromperforming those obligations rendered un-performable by the Force Majeure Event. During aForce Majeure Event, the Company shall use reasonable efforts to limit damages to the Userand to resume its performance pursuant to these Terms of Service.</p>
                                </li>
                                <li>
                                    <h2>RELATIONSHIP OF THE PARTIES</h2>
                                    <p>The User agree that no joint venture, partnership, employment, or agency relationship existsbetween the User and the Company as a result of these Terms of Service, the User’s use ofthe Website, or the availing of Services from the Company.</p>
                                </li>
                                <li>
                                    <h2>ASSIGNMENT</h2>
                                    <p>The User shall not assign or transfer the rights and obligations if the User under these Termsof Service, including any assignment or transfer by reason of merger, reorganization, sale ofall or substantially all of its assets, change of control or operation of law, without theCompany’s prior written consent of the Company may assign or transfer the rights andobligations contained in these Terms and Conditions to any Person.</p>
                                </li>
                                <li>
                                    <h2>GOVERNING LAW AND JURISDICTION</h2>
                                    <p>19.1. Governing Law: This Agreement shall be governed by and construed in accordance with thelaws of Republic of India and both Parties hereby submit to the exclusive jurisdiction of thecourts of Gurugram, Haryana.</p>
                                    <p>19.2. Dispute Resolution: This Agreement and any matter arising from or in connection with thisAgreement shall be governed by and construed in accordance with the laws of India. TheParties agree that they will use their best efforts to amicably resolve any dispute arising out ofor in relation to this Agreement by mutual negotiation. In case the Parties fail to resolve anydispute within 30 (thirty) days from the date of notification of the dispute by either Party,such unresolved dispute shall be settled through arbitration under the Indian Arbitration andConciliation Act,1996 by a sole arbitrator to be mutually appointed by the Company. Thearbitration award shall be final, conclusive and binding on both the parties. The venue of thearbitration shall exclusively be at Gurugram, Haryana and it shall be conducted in the Englishlanguage only.</p>
                                </li>
                                <li>
                                    <h2>CONFIDENTIALITY</h2>
                                    <p>For the purposes of this Terms of Service, the term “Confidential Information” shall meanand include all tangible and intangible information obtained, developed or disclosed oraccessed including all documents, data papers and statements and trade secrets. In case theUser comes into possession of any Confidential Information of the Company the User shallsafeguard the same and shall not disclose such Confidential Information without the priorwritten consent of the Company.</p>
                                </li>
                                <li>
                                    <h2>CONTACT INFORMATION</h2>
                                    <p>21.1. Questions about these Terms and Conditions should be sent to the Company to <a href="mailto:hello@thevacationpod.com">hello@thevacationpod.com</a>. Any notices to the Company in connection with the Website or these Terms andConditions must be sent to <a href="mailto:hello@thevacationpod.com">hello@thevacationpod.com</a>.</p>
                                    <p>21.2. Notice shall be sent to the contact address set forth here (as such may be changed bynotice given to the other party), shall be deemed delivered as of the date of actualreceipt.</p>
                                    <p>21.3. The Company may give telephonic notice to the User by calls to appropriatenumbers, by messaging to the telephone number, or by an email to the email addressof the User available on record with the Company. The User’s agree that in case thereare any changes in any information provided by the User to the Company, includingthe User’s phone number, e-mail address and other contact details, the User will besolely responsible to update them regularly. The User agrees that all agreements,notices, disclosures and other communications that Company provide to the User’selectronically satisfy any legal requirement that such communications be in writing.If the Company sends any communication by e-mail or to the User’s phone number,it shall be sent to the User’s e-mail address or phone number available on the recordscreated by the User on the Website and it shall be deemed to have been received bythe User once it is reflected as sent in the outbox of the e-mail id or at the time ofdispatch of the message.</p>
                                </li>
                                <li>
                                    <h2>ENTIRE UNDERSTANDING</h2>
                                    <p>These Terms and Conditions and Privacy Policy constitutes the entire agreement betweenthe Company and the User pertaining to the subject matter hereof and supersedes in theirentirety all written or oral agreements between the Company and the User.</p>
                                </li>
                                <li>
                                    <h2>SURVIVAL</h2>
                                    <p>Rights and obligations under these Terms, which by their nature should survive or areexpressly so stated herein, shall remain in full force and effect notwithstanding any expiryor termination of the Terms.</p>
                                </li>
                                <li>
                                    <h2>GRIEVANCE OFFFICER</h2>
                                    <p>In compliance with Information Technology Act, 2000, and the rules made thereunder,the grievance officer of the Company for the purpose of this Terms shall be the following:</p>
                                    <p>Email: <a href="mailto:hello@thevacationpod.in">hello@thevacationpod.in</a></p>
                                    <p>Phone No. : <a href="tel:+919310685599">+91 9310685599</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsConditions;