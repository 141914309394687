import avatar1 from "../../assets/avatar_kunal.png";
import avatar2 from "../../assets/avatar_deepak.jpg";
import avatar3 from "../../assets/avatar_anshul.jpg";
import avatar4 from "../../assets/avatar_sahaj.jpg";
import avatar5 from "../../assets/avatar_rituja.png";
import avatar6 from "../../assets/avatar_abhijit.jpg";

export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const testimonials = [
    {
        id: 1,
        name: 'Kunal',
        avatar: avatar1,
        text: "It's a wonderful place to stay in Jaipur. It has such a lively feeling and you can truly feel like a home away from home. I would highly recommend this property. The caretaker Mr.Shravan is a humble and nice person.",
    },
    {
        id: 2,
        name: 'Deepak',
        avatar: avatar2,
        text: 'Saw their pics on social media and instantly made a 1 Night plan for weekend getaway to Jaipur and booked this villa. It totally looks like it’s pictures and description. The owners were super pro-active and very helpful. A great experience at Vacation Pod.',
    },
    {
        id: 3,
        name: 'Anshul',
        avatar: avatar3,
        text: 'We had a fantastic stay at Vacation Pod. The place was exactly what I was looking for. The helper in the house was cherry on the cake. Although my kid didn’t accompany but I am coming back with family and kids next time.',
    },
    {
        id: 4,
        name: 'Sahaj',
        avatar: avatar4,
        text: 'Our stay here was amazing!! Warm and friendly host, great location, an entire apartment at this cost in Jaipur is a steal!! Would love to visit again and make this a regular hangout for all our Jaipur visits!!!',
    },
    {
        id: 5,
        name: 'Rituja',
        avatar: avatar5,
        text: 'One of the best property to explore in Jaipur with the family. Best place for get together, weekend getaway or a party. Explore and enjoy your fun time here with spacious rooms along with extraordinary services!!',
    },
    {
        id: 6,
        name: 'Abhijit',
        avatar: avatar6,
        text: 'It\'s a peaceful place which has all the essential amenities, easily accessible location and a wonderful host. My favourite place in the entire house was the roof.  Both of my naughty kids - Alex (Golden Retriever) and Koko (Shih Tzu) just loved the place.'
    }
    // Add more testimonials as needed
];