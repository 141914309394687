import Image1 from "../../assets/instagram_pics/insta1.png";
import Image2 from "../../assets/instagram_pics/insta2.png";
import Image3 from "../../assets/instagram_pics/insta3.png";
import Image4 from "../../assets/instagram_pics/insta4.png";
import Image5 from "../../assets/instagram_pics/insta5.png";
import Image6 from "../../assets/instagram_pics/insta6.png";
import Image7 from "../../assets/instagram_pics/insta7.png";
import Image8 from "../../assets/instagram_pics/insta8.png";

export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const instaImages = [Image1, Image2, Image3, Image4, Image5, Image6, Image7 , Image8];