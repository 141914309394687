
import SEO from './SEO';

function PrivacyPolicy() {
    return (
        <>
            <SEO title='Privacy Policy | The Vacation Pod' />
            <section className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-section">
                            <h1 className="mt-page-header">Privacy Policy</h1>
                            <p>This Privacy Policy governs the use and processing of information by The Vacation Pod Pvt Ltd, (“Company”) an Indian company registered under the laws of India. It applies to the information collected when Users utilize the Website and other services connected to this Privacy Policy. The Company is dedicated to ensuring that any use or sharing of user information adheres to the procedures outlined in this Privacy Policy.</p>
                            <p>The capitalized terms used in this Privacy Policy, but not defined in this Privacy Policy, shall have the meaning given to such terms in the Terms and Conditions</p>
                            <p>Before using the site, application, or submitting any personal information, please carefully review this Privacy Policy. By accessing the Company’s website, using its services, or providing any information (including information regarding other individuals), the User agrees to the collection and use of the disclosed information in accordance with this Privacy Policy. This includes the User’s consent to the collection, use, sharing, and disclosure of their information as specified in this Privacy Policy.</p>
                            <p>It’s important to note that this Privacy Policy also applies to user information provided by other users. In such cases, the user providing the information confirms and warrants that they have the necessary authority to provide the user information. This Privacy Policy is a part of and incorporated within, and is to be read along with the Terms and Conditions (the “Terms” or “Terms & Conditions”).</p>
                            <ul className="terms-lavel-1">
                                <li>
                                    <h2>AMENDMENT</h2>
                                    <p>The Company reserves the right to modify and update this Privacy Policy periodically. These changes may be implemented without prior notice, but they will only be applicable to future activities and information, not retroactively, unless stated otherwise. Users are advised to review this Privacy Policy before accessing any services provided by the Company to ensure a clear understanding of how their personal information will be utilized.</p>
                                </li>
                                <li>
                                    <h2>LINKS TO THIRD PARTY WEBSITES</h2>
                                    <p>This Privacy Policy outlines the procedures and practices concerning the use and disclosure of User Information collected by the Company. However, the Website may include links to external websites. Please note that personal information collected when visiting such websites is not governed by this Privacy Policy. It is important for Users to exercise caution and review the privacy policies of those websites before accessing them. The User acknowledges that the Company is not responsible for and has no control over the practices and content of any third- party website accessed through the links provided on the Company’s website.</p>
                                </li>
                                <li>
                                    <h2>COLLECTION OF INFORMATION</h2>
                                    <p>The Company collects two basic types of information from a User in conjunction with a User’s use of the Website i.e. personal information, which is any information that exclusively identifies a User which may include but is not limited to the User’s phone number and non-personal information, which is information that does not exclusively identify a User but is related to the User, including but not limited to the information about a User’s interests, demographics and use of the sites. In case the User is not the intended recipient of the services and Website, the User should contact the Company at the earliest. Towards the provision of the services by the Company and the use of the Website, the Company shall in addition to the information described above, collect the following information from a User (“User Information”):</p>
                                    <p>Location</p>
                                    <p>Information about the location of the User’s device used to access the services from the Company. This is used for the provision of the services, pricing related information, for maintenance of records for audit purposes and other matters.</p>
                                    <p>Personal information</p>
                                    <p>Information about the name, email address, contact information and details of official documents. This is used for the provision of the services and for maintenance of records for audit purposes.</p>
                                    <p>Contacts</p>
                                    <p>Information of contacts and accounts information to detect references and accounts information to autofill data during a User’s requests for services.</p>
                                    <p>Other applications</p>
                                    <p>The User’s use of other software or applications which are linked or used in the provision of the services e.g. any third party sites though which a User may have accessed the services. Others As may be explicitly required & requested from time to time.</p>
                                </li>
                                <li>
                                    <h2>USAGE OF USER INFORMATION</h2>
                                    <p>User Information will be used by the Company for the purposes which the Company considers necessary for achieving a seamless, efficient and safe experience, customized to a User’s needs and use the personal information to provide services to a User, enhance the operation of the services and improve the Company’s marketing and promotional efforts. The processing of the User’s Information is for the purposes of:</p>
                                    <p>(a) to provide, maintain and enhance a User experience in relation to the application or the website;</p>
                                    <p>(b) to provide and deliver the services requested by the User, and send User related information including confirmations and invoices;</p>
                                    <p>(c) to send technical notices, updates, security alerts, and support and administrative messages to the User;</p>
                                    <p>(d) to take up and investigate any complaints/ claim/ disputes;</p>
                                    <p>(e) to respond to the User queries or feedback submitted by the User;</p>
                                    <p>(f) to monitor and analyze trends, usage, and activities in connection with the services;</p>
                                    <p>(g) to link or combine with information the Company receives from others to understand User’s needs and provide better service;</p>
                                    <p>(h) to carry out any other purpose for which the information was collected; and</p>
                                    <p>(i) to share the data such as back testing, user action and processed data for internal and external services with the third parties without User’s consent.</p>
                                </li>
                                <li>
                                    <h2>COOKIES</h2>
                                    <p>(a) The Company shall use various technologies to collect information, including cookies and web beacons. Cookies are small data files typically stored on User’s computer’s hard drive or in device memory that helps to improve the Company’s services and User’s experience, see which areas and features of the Company’s services are popular and count visits, manage the registration process for accounts, remember User’s site preferences, retain certain information to process orders for exchange transactions, and retain information to provide User with support.</p>
                                    <p>(b) The Company also use cookies stored in emails to help us confirm User’s receipt of, and response to such emails. Web beacons are electronic images that may be used in the Website or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness.</p>
                                </li>
                                <li>
                                    <h2>STORAGE OF USER INFORMATION</h2>
                                    <p>User Information held by the Company is stored and managed in accordance with applicable laws. The storage and transfer of User Information adhere to all relevant regulations, including the Information Technology Act, 2000, and the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. It’s important to note that the services have implemented reasonable security measures as mandated by the Information Technology Act, 2000, to safeguard User Information against loss or misuse. The Company utilizes secure servers when users modify or access their account information.</p>
                                    <p>Please be advised that the Company cannot guarantee that there will be no unintentional disclosures of User Information and data due to factors such as data breaches, unauthorized access, or security failures. The Company does not provide any warranties and shall not be held liable for breaches in the security of User Information resulting from malicious attacks, errors, or omissions that are not willfully initiated by the Company. Ultimately, the User assumes the risk associated with any transmission of their information.</p>
                                </li>
                                <li>
                                    <h2>DISSEMINATION OF USER INFORMATION</h2>
                                    <p>Where any service requested by a User involves a third party, such information as is reasonably necessary by the Company to carry out the User’s service request maybe shared with such third party and the Company may also obtain details and information from the third party on usage. The Company may also engage with other companies/ persons to complete services, processing payments, and providing customer service. The User understand that they have access to personal information needed to perform their functions. However, the payment and transaction details shall not be shared with other users or third parties under any circumstances.</p>
                                    <p>Additionally, User Information may be shared by the Company with a third-party business entity in case of any proposed merger, acquisition or re-organization, amalgamation, restructuring of business.</p>
                                    <p>The Company may be required to disclose any information that is lawfully sought from it by a judicial or other competent body pursuant to applicable laws.</p>
                                    <p>The Company may anonymize and/or de-identify information collected from the Users. The Company’s use and disclosure of aggregated and/or de-identified information is not restricted by this Privacy Policy and it may be used and disclosed to others without limitation.</p>
                                </li>
                                <li>
                                    <h2>RETENTION OF USER INFORMATION</h2>
                                    <p>The Company shall retain the personal information of the User as long as it serves the purpose(s) for which it was initially collected as stated in the Privacy Policy or subsequently authorized. The Company may continue processing User’s personal information for a period beyond such period only for the time and to the extent such processing reasonably serves the purposes of archiving, and subject to the protection of this Privacy Policy. After the expiry of this time period, the Company may either delete User’s personal information or retain it in a form such that it does not identify the User personally.</p>
                                </li>
                                <li>
                                    <h2>COMMUNICATION POLICY</h2>
                                    <p>When the User sets up an account or receives any request pursuant to the provision of the services, the User is deemed to have opted in to receive emails and messages from the Company and its service providers in connection with the provision of the services as well as any marketing and promotional agencies. While the User may opt out of promotional messages, the User cannot opt out of receiving certain administrative, financial, technical and legal information from the Company in connection with the services.</p>
                                </li>
                                <li>
                                    <h2>ALTERATION OF USER INFORMATION</h2>
                                    <p>In case the User requests any change or deletion of User Information of the User, or permanently delete the User’s, the User contact the Data Protection and Grievance Officer. Do note that the Company is not obligated to alter or vary or delete any User Information which it is required to maintain under applicable laws or pursuant to contractual requirements of the Company.</p>
                                </li>
                                <li>
                                    <h2>DATA BREACH AND DISCLOSURE NOTIFICATION</h2>
                                    <p>In the event of a data breach or unauthorized disclosure of User Information that poses a high risk to the rights and freedoms of the User, unless the Company has effectively mitigated such risks, the Company will promptly notify the affected Users. The notification will include information about the breach or incident, its consequences, and contact details of the Company’s personnel who can address any inquiries from Users regarding the breach. If a governmental or regulatory authority requests the Company to provide User Information, unless explicitly instructed otherwise by the authority, the Company will disclose to the User the details of the request for their User Information.</p>
                                </li>
                                <li>
                                    <h2>CONTACT DETAILS</h2>
                                    <p>In case a User has any queries or any discrepancies / grievances with respect to the User Information provided by a User, please contact the Company’s Data Protection and Grievance Officer by writing at:</p>
                                    <p>Email: <a href="mailto:hello@thevacationpod.in">hello@thevacationpod.in</a></p>
                                    <p>Phone No. : <a href="tel:+919310685599">+91 9310685599</a></p>
                                </li>
                                <li>
                                    <h2>UPDATES TO THE PRIVACY POLICY</h2>
                                    <p>The Company reserves the right, at any time, to add to, change, update, or modify this Privacy Policy. The latest Privacy Policy will be available on this page.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;