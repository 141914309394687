
import SEO from './SEO';

function PageNotFound() {
    return (
        <>
            <SEO title='404 Page not found | The Vacation Pod' />
            <section className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12 content-section">
                            <h1 className="display-1 text-white text-center mt-5 text-uppercase">404<br />page not found</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default PageNotFound;