import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, instaImages } from './ServiceSlider.constants';
import './ServiceSlider.css';

const ServiceSlider = () => {

    return (
        <section className="container-fluid service-slider-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Carousel responsive={responsive}>
                            {instaImages.map(item => (
                                <div className="slide-option">
                                    <img src={item} alt="" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section >
    )
};

export default ServiceSlider;